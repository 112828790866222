<template>
  <div class="attachment-container">
    <img :src="defaultAvatar" alt="avatar" v-if="!attachment">
    <img :src="preview" alt="avatar" v-else>
    <label for="profile-image" class="my-profile-image-label">
      Select Image
    </label>
    <input
      id="profile-image"
      name="profile-image"
      type="file"
      accept="image/jpeg,image/png"
      style="display:none;"
      @change="onFileSelected"
    />
  </div>
</template>

<script>
import Avatar from '../assets/images/default_avatar.png';

export default {
  data(){
    return{
      defaultAvatar: Avatar,
      attachment: null,
      preview: null
    }
  },
  methods: {
    onFileSelected(e){
      const filename = e.target.files[0].name;
      const fileType = e.target.files[0].type;

      //Check file validity
      if (filename.lastIndexOf(".") <= 0 || !fileType.includes('image')) {
        return this.$swal({
          title: 'Invalid Attachment',
          text: 'File Type Not Allowed',
          icon: 'error',
        });
      }else if(filename.split('.').length > 2){
        return this.$swal({
          title: 'Invalid Attachment',
          text: 'File must not include more than 1 period',
          icon: 'error',
        });
      }


      this.attachment = e.target.files[0];
      this.preview = URL.createObjectURL(e.target.files[0]);
      this.$emit("image-upload", this.attachment)
    }
  }
}
</script>

<style lang="scss" scoped>
.attachment-container{
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  img{
    height: 70px;
    width: auto;
  }

  .my-profile-image-label{
    cursor: pointer;
    background: var(--gray414241);
    color: var(--whiteFFFFFF);
    padding: 0.8rem 2.3rem;
    border-radius: 50px;
    font-weight: 700;
    margin-left: 20px;
  }
}
</style>