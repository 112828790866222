<template>
  <div class="card-wrapper">
    <router-link to="/user/login" tag="a"><img src="@/assets/images/kenanga_logo.png"></router-link>
    <h2>{{this.title}}</h2>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props:['title']
}
</script>


<style lang="scss" scoped>
  .card-wrapper{
    background: var(--whiteFFFFFF);
    padding: 2rem 3.5rem;
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3.5rem 0;

    @media screen and (max-width:450px){
      margin: 0;
      border-radius: 0;
      padding: 2rem 1.5rem;
      height: 100%;
      width: 100%;
      justify-content: flex-start;
    }

    a{
      margin-bottom: 1rem;
      width: 80%;
      max-width: 300px;
      height: auto;
    }

    h2{
      color: var(--gray414241);
      width: 100%;
      text-align: center;
    }
  }
</style>