<template>
  <div class="checkbox-container">
    <ValidationProvider :name="id" :rules="rules" v-slot="{ errors }">
      <div class="checkbox-wrapper">
        <input
          type="checkbox"
          :id="id"
          :name="id"
          @change="onChecked"
          v-model="isChecked"
        />
        <span class="checkbox-icon">
          <font-awesome-icon icon="check" />
        </span>
        <label :for="id">
          <slot></slot>
        </label>
        <span class="slot-btn" @click="triggerModal"
          ><slot name="button"></slot
        ></span>
      </div>
      <small class="error">{{ errors[0] }}</small>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: ["id", "value", "label", "rules"],
  data() {
    return {
      isChecked: "",
    };
  },
  methods: {
    onChecked(event) {
      if (event.target.checked) {
        this.isChecked = true;
      } else {
        this.isChecked = "";
      }
      this.$emit("onChecked", this.isChecked);
    },
    triggerModal() {
      this.$emit("triggerModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  display: flex;
}

.checkbox-container {
  display: flex;
  align-items: center;

  .checkbox-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    pointer-events: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--redC41230);
    border: 2px solid var(--redC41230);
    border-radius: 5px;

    svg {
      opacity: 0;
      font-size: 10px;
    }
  }

  input {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #000;
    outline: none;
    opacity: 0;
    &:checked {
      + .checkbox-icon {
        > svg {
          opacity: 1;
          animation: scaleIn 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }
      }
    }
  }

  label {
    padding-left: 15px;
    cursor: pointer;
    font-size: 14px;
    color: var(--gray414241);
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: left;

    @media screen and (max-width: 450px) {
      font-size: 12px;
      margin-bottom: 15px;
    }

    a {
      text-underline-offset: 10px;
    }
  }

  @keyframes scaleIn {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }

  .error {
    color: red;
    text-align: left;
    display: block;
  }
}

.slot-btn {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: $red;
  text-decoration: underline;
  text-underline-offset: 5px;
  margin-left: 5px;
}
</style>
