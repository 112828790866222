import axios from "@/axios.config.js";

const checkEmail = (email) => axios.get('/api/v1/email_check',null, {params: { email }})

const signUp = (body) => axios.post('/api/v1/users/signup',body);

const login = (body) => axios.post('/api/v1/users/login',body);

const socialLogin = (params) => axios.post('/api/v1/social_login',null, {
  params: {
    provider: params.provider,
    access_token: params.access_token
  }
})

const logout = () => axios.post('/api/v1/users/logout');

const userProfile = () => axios.get('/api/v1/users/me');

const updateProfile = (params) => axios.put(`/api/v1/users/`,params)

const forgotPassword = (email) => axios.post('/api/v1/users/forgot_password',null,{
  params: {
    email : email
  }
})

const referralsIndex = () => axios.get('/api/v1/users/referrals')

const checkReferralCode = (code) => axios.get(`/api/v1/users/referral_code_check?referral_code=${code}`)

const resendVerificationEmail = (email) => axios.post('/api/v1/users/resend_email_verification',{
  email : email
})

const checkLoginToken = (body) => axios.post('/api/v1/users/checkLoginToken',body)

export{
  checkEmail,
  signUp,
  login,
  socialLogin,
  logout,
  userProfile,
  updateProfile,
  forgotPassword,
  referralsIndex,
  checkReferralCode,
  resendVerificationEmail,
  checkLoginToken
}

