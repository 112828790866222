import axios from "@/axios.config.js";


const getCountry = () => axios.get('/api/v1/countries');

const getState = (countryId) => axios.get(`/api/v1/countries/${countryId}/states`);

export {
  getCountry,
  getState
}