<template>
  <div ref="cardLoader">
    <Card title="Get Hands On With Futures Tranding!" >
      <Form title="Sign Up" :addSpacer="false" @submit="submitForm" class="form">
        <div class="grid">
          <UploadAttachment @image-upload="handleImage" />
          <Input
            id="display name"
            type="text"
            placeholder="Display Name"
            hasIcon="true"
            iconType="user"
            :label="display_name_label"
            class="bottom-spacing"
            rules="required|max:20"
            maxLength="20"
            @change="updateData($event, 'display_name')"
          />

          <!-- Name -->
          <Input
            id="name"
            type="text"
            placeholder="Name"
            label="Name (As per NRIC/ Passport/ Other ID)*"
            class="bottom-spacing span-width"
            rules="required|min:6"
            @change="updateData($event, 'name')"
          />

          <!-- Phone Number -->
          <Input
            id="phone number"
            type="tel"
            placeholder="Mobile Number"
            hasIcon="true"
            iconType="telephone"
            label="Mobile Number*"
            class="bottom-spacing"
            rules="required|numeric"
            @change="updateData($event, 'phone')"
          />

          <!-- Email -->
          <Input
            id="email"
            type="email"
            placeholder="Email"
            hasIcon="true"
            iconType="email"
            label="Email*"
            class="bottom-spacing"
            rules="required|email"
            @change="updateData($event, 'email')"
          />

          <!-- Gender -->
          <DropdownSelect
            id="gender"
            label="Gender*"
            hasIcon="true"
            :options="this.genderList"
            iconType="gender"
            class="bottom-spacing"
            @change="updateData($event, 'gender')"
            rules="required"
          />

          <!-- Referral Code -->
          <Input
            id="referral"
            type="text"
            placeholder="Referral Code"
            label="Referral Code"
            class="bottom-spacing"
            :noInputValue="true"
            v-model="user.referral_code"
          />

          <!-- Nationality -->
          <DropdownSelect
            id="nationality"
            label="Nationality*"
            hasIcon="true"
            :options="this.countryList"
            iconType="nationality"
            class="bottom-spacing"
            @change="updateData($event, 'country_id')"
            rules="required"
          />

          <!-- State -->
          <DropdownSelect
            id="state"
            label="State*"
            hasIcon="true"
            :options="this.stateList"
            iconType="nationality"
            class="bottom-spacing"
            @change="updateData($event, 'state_id')"
            :readonly="checkNationality"
          />

          <!-- Password -->
          <Input
            id="password"
            type="password"
            placeholder="Password"
            hasIcon="true"
            iconType="password"
            label="Password*"
            class="bottom-spacing"
            autoComplete="new-password"
            rules="required|verify_password"
            vid="confirmation"
            @change="updateData($event, 'password')"
          />
          <!-- Confirm Password -->
          <Input
            id="confirm password"
            type="password"
            placeholder="Confirm Password"
            hasIcon="true"
            iconType="password"
            label="Confirm Password*"
            class="bottom-spacing"
            autoComplete="new-password"
            rules="required|password:@confirmation"
            @change="updateData($event, 'password_confirmation')"
          />

          <!-- Terms and Condition -->
          <CheckBox id="terms and conditon" class="span-width" rules="required">
            By submitting your information, you agree to our
            <b
              ><a href="https://www.kenangafutures.com.my/disclaimer/"
                style="font-size:inherit" target="_blank">Terms and Conditions</a
              ></b
            >
            and
            <b
              ><a href="https://www.kenangafutures.com.my/pdpa/"
                style="font-size:inherit" target="_blank">PDPA Notice</a
              ></b
            >
          </CheckBox>

          <VueHcaptcha :sitekey="recaptchaKey" @verify="onVerify" style="margin:auto"></VueHcaptcha>
          <SubmitButton>Sign Up</SubmitButton>
        </div>

        <Message :class="{ error: this.error }">{{ this.error }}</Message>
       
        <span
          >Have an account?
          <router-link tag="a" :to="{ name: 'Login' }">Sign In</router-link></span
        >
      </Form>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Form from "@/components/Form";
import Input from "@/components/Input";
import CheckBox from "@/components/CheckBox";
import SubmitButton from "@/components/SubmitButton";
import UploadAttachment from "@/components/UploadAttachment";
import DropdownSelect from "@/components/DropdownSelect";
import Message from "@/components/Message";
 import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

//API
import { signUp, updateProfile, checkReferralCode } from "@/services/api/users.js";
import { getCountry, getState } from "@/services/api/countries.js";
import { attachments } from "@/services/api/others.js"

//Store
import {eventBus} from '@/main.js';

export default {
  components: {
    Card,
    Form,
    Input,
    CheckBox,
    SubmitButton,
    UploadAttachment,
    DropdownSelect,
    Message,
    VueHcaptcha
  },
  data() {
    return {
      countryList: null,
      stateList: null,
      display_name_label: "Display Name (0/20)",
      recaptchaKey: null,
      robot: null,
      genderList: [
        {
          id: "M",
          name: "Male",
        },
        {
          id: "F",
          name: "Female",
        },
        {
          id: "O",
          name: "Prefer Not To Say"
        }
      ],
      user: {
        display_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        name: "",
        phone: null,
        gender: "",
        country_id: null,
        state_id: null,
        referral_code: this.$route.query.referral_code|| '',
      },
      attachment: null,
      error: "",
      timeoutSearch: null,
      errorReferralCode: false,
      checkNationality: false
    };
  },
  async created() {
    this.recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY;
    try {
      const response = await getCountry();
      if (response.status === 200)
        this.countryList = response.data.response.countries;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async uploadDocument(){
      const formData = new FormData();
      formData.append("attachment", this.attachment);
      const req = await attachments(formData)
    },
    async updateData(data, attr) {
      this.user[attr] = data;

      if (attr === "country_id") {
        
        //Check if nationality is Malaysian
        data !== 1 ? this.checkNationality = true : this.checkNationality = false;
        
        try {
          const response = await getState(this.user.country_id);
          if (response.status === 200)
            this.stateList = response.data.response.states;
        } catch (error) {
          console.log(error);
        }
      }
    },
    handleImage(val) {
      this.attachment = val;
    },
    async submitForm() {
      this.error = "";

      if(this.errorReferralCode || !this.robot) return;

      //Check phone number country code
      if (this.user.phone.toString().substr(0, 2) === "01") {
        this.user.phone = "6" + this.user.phone;
      } else if (this.user.phone.toString().substr(0, 1) === "1") {
        this.user.phone = "60" + this.user.phone;
      }

      try {
        eventBus.$emit('loader',true);
        const register = await signUp(this.user);

        const accessToken = register.data.response.token;
        this.$store.commit("manualSetToken",accessToken);

        if (this.attachment) {
          const formData = new FormData();
          formData.append("attachment", this.attachment);
          const sendAttachment = await attachments(formData);
          const obj = {
            avatar: [sendAttachment.data.response.filename],
          };
          await updateProfile(obj);          
        }
        this.$store.commit("manualSetToken",null);

        eventBus.$emit('loader',false);
        this.$router.push({name: "VerifyEmail", params: {email: this.user.email}})
      } catch (error) {
        eventBus.$emit('loader',false);
        this.error = error.response.data.userMessage;
      }
    },
    async checkReferralCode(){
      try {
        await checkReferralCode(this.user.referral_code);
        this.errorReferralCode = false;
        this.error = '';
      } catch (error) {
        this.error = error.response.data.userMessage;
        this.errorReferralCode = true;
      }
    },
    onVerify: function (token, eKey) {
      if (token) this.robot = true;
    },
  },
  computed: {
    displayNameLength() {
      return this.user.display_name.length;
    },
  },
  watch: {
    displayNameLength(data) {
      this.display_name_label = `Display Name (${data}/20)`;
    },
    'user.referral_code':{
      handler: function(val){
        clearTimeout(this.timeoutSearch);
      
        var vm = this;

        if(val.trim().length === 0){
          this.error = ''
          this.errorReferralCode = false;
        }else{
          this.timeoutSearch = setTimeout(function () {
            vm.checkReferralCode();
          }, 1000);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.form{
  margin-top: 2em;
}

.grid {
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: repeat(2, 300px);
  column-gap: 50px;
  align-items: flex-start;

  .span-width {
    grid-column: 1 / 3;
  }

  &:first-of-type {
    align-self: flex-start;
  }

  @media screen and (max-width: 768px) {
    display: initial;
  }
}

button {
  margin: 3rem auto;
}

span {
  font-size: 15px;
  display: block;
  text-align: center;
  margin: 0 auto;

  a {
    text-align: center;
    color: var(--redC41230);
    font-size: 15px;
    text-decoration: underline;
  }
}
</style>
