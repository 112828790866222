<template>
	<div class="input-container">
    <ValidationProvider :name="id" :rules="rules" v-slot="{ errors }"> 
      <label :for="id">{{ label }}</label>
      <div class="input-wrapper">
        <img :src="returnIconImage" v-if="hasIcon" class="icon" />
        <img src="../assets/icons/arrow_down.png" class="select-arrow" />
        <select :name="id" :id="id" @change="updateValue" v-model="inputValue"  :style="{'padding-left': !hasIcon ? '1rem' : '3rem'}" :disabled="readonly">
          <template v-for="option in options">
            <option :value="option.id" :key="option.id">{{ option.nationality || option.name || option.filename}}</option>
          </template>
        </select>
      </div>
      <small class="error">{{ errors[0] }}</small>
    </ValidationProvider>
	</div>
</template>

<script>
	import genderIcon from "../assets/icons/gender.png";
	import nationalityIcon from "../assets/icons/nationality.png";

	export default {
		props: {
      id: {
        required: true
      }, 
      label:{
        required: false
      }, 
      errorMessage:{
        required: false
      },  
      readonly:{
        required: false
        
      },  
      hasIcon:{
        required: false,
        default: false,
      }, 
      options:{
        required: true,
      }, 
      iconType:{
        required: false,
        default: false,
      }, 
      defaultOption:{
        required: false,
        default: null,
      },
      selected:{
        required: false,
      },
      rules:{
        required: false
      }
    },
	methods: {
		updateValue() {
			this.$emit("change", this.inputValue);
		},
	},
	data() {
		return {
			inputValue: "",
			gender: genderIcon,
		};
    },
    computed:{
      returnIconImage(){
        switch (this.iconType) {
          case "gender":
            return genderIcon
        
          case "nationality":
            return nationalityIcon
        }
      }
    },
    watch:{
      selected: {
        handler:function(data){
          this.selected = data;
          this.inputValue = data;
          if(data == undefined || data == this.defaultOption) this.inputValue = "";
        },
        // immediate: true
      }
    }
	};
</script>

<style lang="scss" scoped>
	.input-container {
		margin-bottom: 0;
		height: min-content;

		&.bottom-spacing {
      margin-bottom: 3rem;
      
      @media screen and (max-width:768px){
        margin-bottom: 1.5rem;
      }
		}

		&.last {
			margin-bottom: 1.2rem;
		}

		label {
			display: block;
			text-align: left;
			margin-bottom: 5px;
			color: var(--gray414241);
			font-size: 14px;
		}

		.input-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			border: 1px solid var(--borderGrayECECEC);
			border-radius: 5px;
			overflow: auto;

			.icon {
				position: absolute;
				left: 0;
				object-fit: contain;
				margin: 0 1rem;
				max-width: 20px;
				height: auto;
			}

			select {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none; /* Remove default arrow */
				font-size: 16px;
				letter-spacing: 1px;
				width: 100%;
				border: none;
				padding: 1rem 0;
				color: var(--gray414241);

				&:active,
				&:focus {
					outline: none;
					border: none;
				}

				&:required,
				&:invalid {
					box-shadow: none;
				}

				option {
					font-size: 16px;
					font-weight: 500;
				}
			}

			.select-arrow {
				position: absolute;
				right: 20px;
        pointer-events: none;
			}

			span {
				cursor: pointer;
				position: absolute;
				right: 20px;
			}
		}
  }
  
  .error {
    color: red;
    text-align: left;
    display: block;
  }
</style>
